










import ContactForm from '@/components/contact/contact-form.vue';
import HomeAbout from '@/components/home/home-about.vue';
import HomeCarousel from '@/components/home/home-carousel.vue';
import HomePartnership from '@/components/home/home-partnership.vue';
import HomeRent from '@/components/home/home-rent.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'home',
  components: {
    ContactForm,
    HomeAbout,
    HomeCarousel,
    HomePartnership,
    HomeRent,
  },
});
