













import Vue from 'vue';

export default Vue.extend({
  name: 'home-carousel',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      slides: [
        {
          image: 'living-room_2.jpg',
          headline: 'Nie trać czasu, zarabiaj!',
          text: 'Twoja nieruchomość pod naszą opieką',
        },
        {
          image: 'living-room_1.jpg',
          headline: 'Profesjonalny wynajem na lata',
          text: 'Gwarancja stałego dochodu bez opóźnień',
        },
        {
          image: 'living-room_3.jpg',
          headline: 'Obsługa najmu to nasza pasja',
          text: 'Rozwiążemy każdy problem, naprawimy każdą usterkę',
        },
        {
          image: 'kitchen_1.jpg',
          headline: 'Przygotujemy Twoje mieszkanie',
          text: 'Zapewnimy komfort, nowoczesność i funkcjonalność',
        },
      ].map((slide) => ({ ...slide, image: `${process.env.BASE_URL}img/${slide.image}` })),
    };
  },
});
