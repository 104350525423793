

















































































































import axios from 'axios';
import Vue from 'vue';

const fields = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

export default Vue.extend({
  name: 'contact-form',
  data() {
    return {
      pending: false,
      status: '',
      errors: { ...fields },
      form: { ...fields },
    };
  },
  methods: {
    submit() {
      this.errors = { ...fields };
      this.status = '';
      this.pending = true;

      axios({
        url: '/api/contact',
        method: 'post',
        data: this.form,
      })
        .then((res) => {
          const { data } = res;

          if (!data.status) {
            this.errors = data.errors;
            this.pending = false;
            return;
          }

          this.status = 'SENT';
        })
        .catch(() => {
          this.status = 'ERROR';
          this.pending = false;
        });
    },
  },
});
